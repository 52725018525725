.dictionary-layout  {
  .dictionary-words-sidenav {
    max-height: calc(100vh - 136px);
    overflow-y: auto;
  }

  .words-list {
    max-height: calc(100vh - 177px);
    overflow-y: auto;
  }

  .word-meaning-editor-wrapper {
    min-height: 15rem;
    width: 100%;

    .word-meaning-editor {
      min-height: 15rem;
      height: 100%;
      width: 100%;
    }
  }
}
