@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "react-datepicker/dist/react-datepicker.css";
@import "react-datetime/css/react-datetime.css";
@import "aos/dist/aos.css";
@import "react-image-gallery/styles/scss/image-gallery.scss";
@import "github-markdown-css/github-markdown.css";
@import "github-markdown-css/github-markdown-light.css";
//@import 'draft-js/dist/Draft.css';
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

$brand-color: #0BA0DC;
$custom-green-color: #cefad0;
$custom-red-color: #ffc9bb;
$minor-yellow-color: rgb(250, 204, 21);
$major-orange-color: rgb(251 146 60 );

body {
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

.fade-in {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bg-dreamy {
  background-image: url("../../public/img/bg-texture1.png");
  background-size: cover;
}

.bg-content-dreamy {
  background-image: url("../../public/img/bg-texture-vlight.jpg");
  background-size: cover;
}

.input-disabled,
.input:disabled,
.input[disabled],
.select-disabled,
.select:disabled,
.select[disabled] {
  background-color: #FFF;
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

.tab.tab-active:not(.tab-disabled):not([disabled]) {
  border-style: solid;
  border-color: $brand-color;
  border-bottom-width: 2px;
}

.bg-brand {
  background-color: $brand-color;
}

.text-brand {
  color: $brand-color;
}

.border-brand {
  border-color: $brand-color;
}

.bg-custom-green {
  background-color: $custom-green-color;
}

.bg-custom-red {
  background-color: $custom-red-color;
}

.h-scroll-container {
  height: calc(100vh - 30vh);
  overflow-y: auto;
}

.bg-minor-defect {
  background: $minor-yellow-color;
}

.bg-major-defect {
  background: $major-orange-color;
}

@import 'autocomplete';
@import 'header';
@import 'footer';
@import "login";
@import "ahu_view";
@import "admin";
@import "request_forms";
@import "toggle";
@import "reports";
@import "pacs_layout";
@import "dictionary_layout";

.post-editor .ql-editor {
  min-height: 10rem;
}
