.admin-view {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  min-height: 360px;

  .table-data-container {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .modal-content {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }

  .modal-xl .modal-box {
    max-width: 1200px;
  }

  .modal-lg .modal-box {
    max-width: 720px;
  }

  .modal-full .modal-content {
    max-height: calc(100vh - 280px);
    height: calc(100vh - 280px);
  }

  .modal-full .modal-box {
    max-width: calc(100vw - 20px);
    width: calc(100vw - 20px);
  }

  .modal-xl .modal-content {
    max-height: calc(100vh - 250px);
  }
}

