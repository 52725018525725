.alarms-indicator.indicator .indicator-item {
    --tw-translate-x: 8%;
    --tw-translate-y: -15%;
}

.toast-container div:last-child {
    justify-content: flex-start;
}

.navbar {
    background-image: url("../../public/img/bg-texture1.png");
    background-size: cover;

    padding-top: 0;
    padding-bottom: 0;

    .sidenav-menu {
        .dropdown-content {
            //--tw-translate-y: 7px;

            //height: calc(100vh - 95px);
        }
    }

    .user-details {
        .dropdown-content {
            //--tw-translate-y: 3px;
        }
    }
}

.notifications-container {
    overflow-y: auto;
    max-height: calc(100vh - 70px);
}
