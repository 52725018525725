.pacs-app-layout {
  overflow-x: auto;
  overflow-y: auto;

  &.side-nav-open {
    .side-navigation {
      left: 0;
      width: 250px;
    }

    .app-container {
      width: calc(100vw - 250px);
      left: 250px;
    }
  }

  .side-navigation {
    width: 0;
    left: -250px;
  }

  .app-container {
    width: 100vw;
  }

  .app-content {
    height: calc(100vh - 84px);
    overflow-y: auto;
  }
}
